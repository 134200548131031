import React from "react";
import "../style/style.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import s from '../style/PoliticasPrivacidad.module.scss';
import NavBar from '../components/NavBar';
import Experts from '../components/Experts';
import CTAFooter from '../components/CTAFooter';
import Footer from '../components/Footer';
import PrivacidadBody from '../components/PrivacidadBody';
import { TagManager, TagManagerFooter } from '../components/TagManager';
import MetaData from '../components/MetaData';
import PageView from '../components/PageView';


const PoliticasPrivacidad = () => {
  const politicasPrivacidads = useStaticQuery(graphql`
      {
          gcms {
              politicasPrivacidads (where: {status: PUBLISHED}) {
                titulo
                bajada
                imagen {
                    handle
                    width
                    height
                }
                texto {
                    html
                }
            }
          }
        }`
    ).gcms.politicasPrivacidads;
    return(
      <div>
            <MetaData/>
            <TagManager/>
            <PageView/>
            <div className={s.headerContainer}>
                <NavBar blue={true}/>
            </div>
            <PrivacidadBody policy={politicasPrivacidads}/>
            <Experts/>
            <CTAFooter blue={true}>
                Averiguá sin compromiso a qué crédito podrías acceder
            </CTAFooter>
            <Footer/>
            <TagManagerFooter/>
      </div>
    )
}

export default PoliticasPrivacidad
