import React from "react";
import s from './PrivacidadBody.module.scss';


const PrivacidadBody = ({ policy }) => {
    return (
        <div className={s.container}>
           <h1 className={s.title}>{policy[0].titulo}</h1>
           <p  className={s.subText}>{policy[0].bajada}</p>
           <div className={s.text} dangerouslySetInnerHTML={{__html: policy[0].texto.html}}></div>
        </div>
    )
}

export default PrivacidadBody
